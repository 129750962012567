<template>
  <div class="st">

    <logo class="st-logo" />

    <div class="st-wrap">
      <div class="st-image">
        <img src="@/assets/images/session-timeout/main.svg" alt="Session has expired"/>
      </div>

      <h1 class="st-title">Session has expired</h1>
      <div class="st-description">
        Your session has expired due to inactivity. <br>
        For security purpose, please login to your account again.
      </div>

      <div class="st-buttons">
        <router-link :to="{name: 'login'}" v-slot="{navigate}">
          <button class="default-button" @click="navigate">Back to login</button>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import Logo from "@/libs/Logo";

export default {
  name: 'SessionTimeout',
  components: {
    Logo
  },
  created() {
    // Fix when logout is completed. Close all modals
    this.$modal.hideAll()
  },
}
</script>

<style lang="less">
.st {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-logo {
    padding: 20px 10px;
    margin: 0 auto;
    position: absolute;

    top: 20px;
  }
  &-wrap {
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;
  }
  &-image {
    margin-bottom: 60px;
  }
  &-title {
    font-size: 40px;
    line-height: 48px;
    font-weight: bold;
    color: #FFF;
    padding-bottom: 10px;
  }

  &-description {
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 40px;
  }

}
</style>
